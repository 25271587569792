body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(180deg, #000000, #1a1a1a);
  color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;
}

.app {
  max-width: 1400px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  z-index: 1;
}

.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.rotating-text-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  font-weight: 700;
  color: rgba(255, 0, 0, 0.2);
  text-transform: uppercase;
  animation: rotateGlow 20s linear infinite;
  pointer-events: none;
  z-index: -1;
}

@keyframes rotateGlow {
  0% { transform: translate(-50%, -50%) rotate(0deg); opacity: 0.2; }
  50% { opacity: 0.4; }
  100% { transform: translate(-50%, -50%) rotate(360deg); opacity: 0.2; }
}

.header {
  text-align: center;
  padding: 20px 15px;
  background: linear-gradient(135deg, #0000ff, #ff0000, #ffffff);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.hero-tagline {
  margin: 10px 0;
  overflow: hidden;
}

.tagline-text {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  animation: slideIn 10s infinite linear;
}

@keyframes slideIn {
  0% { transform: translateX(100%); }
  20% { transform: translateX(0); }
  80% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.title {
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ff0000;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
  margin: 0;
}

.letter {
  display: inline-block;
}

.vaccine-letter {
  color: #000000;
}

.police-letter {
  color: #ffffff;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 10px 0 15px;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.auth-section {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.auth-btn {
  padding: 14px 28px;
  background: linear-gradient(45deg, #0000ff, #ff0000);
  color: #ffffff;
  border: none;
  border-radius: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.7);
}

.auth-btn:hover, .auth-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(255, 0, 0, 0.9), 0 0 30px rgba(0, 0, 255, 0.7);
}

.nav {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-link {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
}

.nav-link:hover {
  background: linear-gradient(45deg, #ff0000, #0000ff);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 0, 0, 0.5);
}

.featured-section {
  margin-bottom: 30px;
  text-align: center;
}

.featured-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.featured-video {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
}

.landing-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.landing-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.landing-text {
  font-size: 1.4rem;
  color: #ffffff;
  margin: 0 0 20px;
  line-height: 1.6;
}

.cta-btn {
  padding: 18px 36px;
  font-size: 1.5rem;
  background: linear-gradient(45deg, #ff0000, #0000ff);
  color: #ffffff;
  border: none;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 4px 15px rgba(255, 0, 0, 0.4);
  animation: pulseGlow 2s infinite ease-in-out;
  position: relative;
  overflow: hidden;
}

.cta-btn::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  pointer-events: none;
}

.cta-btn:active::after {
  animation: ripple 0.6s linear;
}

@keyframes ripple {
  to { transform: translate(-50%, -50%) scale(3); opacity: 0; }
}

@keyframes pulseGlow {
  0%, 100% { box-shadow: 0 4px 15px rgba(255, 0, 0, 0.4); }
  50% { box-shadow: 0 6px 25px rgba(255, 0, 0, 0.7); }
}

.cta-btn:hover, .cta-btn:active {
  transform: scale(1.1);
  box-shadow: 0 8px 25px rgba(255, 0, 0, 0.8);
}

.main, .main-content {
  padding: 0 20px;
}

.upload-form {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.upload-form input {
  padding: 12px;
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
}

.upload-btn {
  padding: 12px 24px;
  background: linear-gradient(45deg, #000000, #ff0000);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.upload-btn:hover, .upload-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 0, 0, 0.5);
}

.progress-container {
  width: 100%;
  max-width: 300px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 0.9rem;
  color: #ffffff;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
}

.video-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
}

.video-card:hover {
  transform: scale(1.03);
}

.video-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 15px;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-description, .video-uploader, .video-views {
  font-size: 1rem;
  margin: 0 15px 15px;
  color: #ffffff;
}

.like-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 15px;
}

.like-btn {
  padding: 8px 16px;
  background: linear-gradient(45deg, #0000ff, #ff0000);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.like-btn:hover:not(.liked):not(:disabled) {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(255, 0, 0, 0.5);
}

.like-btn.liked, .like-btn:disabled {
  background: linear-gradient(45deg, #1a1a1a, #800000);
  cursor: not-allowed;
}

.like-count {
  font-size: 0.9rem;
  color: #ffffff;
}

.history-modal, .course-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.history-content, .course-content {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
}

.history-title, .course-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  text-align: center;
}

.history-text, .course-text {
  font-size: 1.2rem;
  color: #ffffff;
  line-height: 1.6;
  margin-bottom: 20px;
}

.close-btn {
  padding: 10px 20px;
  background: linear-gradient(45deg, #ff0000, #0000ff);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: block;
  margin: 20px auto 0;
}

.close-btn:hover, .close-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 0, 0, 0.5);
}

.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.auth-content {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
}

.auth-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  text-align: center;
}

.auth-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-btn {
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

.tab-btn.active {
  background: linear-gradient(45deg, #0000ff, #ff0000);
  color: #ffffff;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-form input {
  padding: 12px;
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.submit-btn {
  padding: 12px;
  background: linear-gradient(45deg, #000000, #ff0000);
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
}

.submit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 0, 0, 0.5);
}

.footer {
  text-align: center;
  padding: 20px 15px;
  background: linear-gradient(135deg, #0000ff, #ff0000, #ffffff);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-top: 30px;
}

.footer-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

.contact-text {
  color: #ffffff;
  font-size: 1.2rem;
  margin: 10px 0;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.social-icon {
  font-size: 1.8rem;
  color: #ffffff;
  transition: transform 0.2s, text-shadow 0.2s;
}

.social-icon:hover {
  transform: scale(1.2);
  text-shadow: 0 0 15px rgba(255, 0, 0, 0.9);
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #ff0000;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite, glowPulse 2s infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes glowPulse {
  0%, 100% { box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(255, 0, 0, 1); }
}

.who-i-am-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.bio-container {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.bio-image {
  flex: 0 0 auto;
}

.christopher-pic {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.section-text.accent-text {
  flex: 1;
  text-align: left;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.ministry-section {
  text-align: left;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  border-left: 5px solid #ff0000;
}

.support-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.8) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="60" viewBox="0 0 100 60"><path fill="rgba(255,255,255,0.1)" d="M0 0h50l50 60H50z"/></svg>') repeat;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.ticker {
  font-weight: 700;
  color: #ff0000;
  display: inline-block;
  animation: tickerGlow 1s infinite alternate;
}

@keyframes tickerGlow {
  0% { text-shadow: 0 0 5px rgba(255, 0, 0, 0.5); }
  100% { text-shadow: 0 0 15px rgba(255, 0, 0, 1); }
}

.pulse-btn {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.products-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.product-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(255, 0, 0, 0.5);
}

.product-title {
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 10px;
}

.product-text {
  font-size: 1.2rem;
  color: #000000;
}

.sponsored-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  overflow-x: hidden;
}

.sponsored-grid {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #ff0000 rgba(255, 255, 255, 0.1);
}

.sponsored-grid::-webkit-scrollbar {
  height: 8px;
}

.sponsored-grid::-webkit-scrollbar-thumb {
  background: #ff0000;
  border-radius: 4px;
}

.sponsored-grid::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.sponsored-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  min-width: 250px;
  max-width: 250px;
  flex: 0 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsored-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 255, 0.5);
}

.sponsored-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.sponsored-title {
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 10px;
}

.sponsored-text {
  font-size: 1.2rem;
  color: #000000;
}

.chips-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.chips-button-container {
  margin: 20px 0;
}

.chips-form-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.chips-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.chips-form input,
.chips-form textarea {
  padding: 12px;
  border: 2px solid #ff0000;
  border-radius: 12px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.chips-form textarea {
  min-height: 100px;
  resize: vertical;
}

.chips-form button {
  /* Inherits .cta-btn styles */
}

.chips-testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  max-width: 300px;
}

.testimonial-quote {
  font-size: 1.2rem;
  font-style: italic;
  color: #ffffff;
}

.testimonial-name {
  font-size: 1rem;
  color: #cccccc;
  margin-top: 10px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #ff0000, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.section-text {
  font-size: 1.4rem;
  color: #ffffff;
  margin: 0 0 20px;
  line-height: 1.6;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.no-videos {
  font-size: 1.5rem;
  text-align: center;
  color: #ffffff;
}

.exclusive-badge {
  background: #ffd700;
  color: #000;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
}
/* Replace existing .news-ticker styles */
.news-ticker {
  background: #333;
  padding: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
  border: 1px solid #ffd700;
  color: #ffd700;
  font-weight: bold;
  font-size: 1rem;
}

.news-ticker span {
  display: inline-block;
  padding-left: 100%; /* Start off-screen */
  animation: ticker 20s linear infinite; /* Adjust speed with duration */
}

@keyframes ticker {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-search {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between input and button */
}

.search-bar {
  width: 80%;
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.search-bar:focus {
  border-color: #007bff; /* Blue focus ring, adjust to match your theme */
}

/* Reuse .auth-btn from header buttons */
.auth-btn {
  padding: 10px 20px; /* Match your header button padding */
  font-size: 16px;   /* Match your header font size */
  background-color: #007bff; /* Default blue, adjust to your header */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-btn:hover {
  background-color: #0056b3; /* Darker shade, match your header hover */
}

.clear-btn {
  padding: 8px 16px; /* Slightly smaller for balance, optional */
}

/* Mobile tweak */
@media (max-width: 768px) {
  .news-ticker {
    font-size: 0.9rem;
    padding: 0.3rem;
  }
}

@media (max-width: 768px) {
  .title { font-size: 2rem; }
  .subtitle { font-size: 1rem; }
  .tagline-text { font-size: 1.2rem; }
  .auth-btn { padding: 12px 24px; font-size: 1rem; }
  .nav { flex-wrap: wrap; gap: 10px; }
  .nav-link { padding: 8px 16px; font-size: 1rem; }
  .landing-title { font-size: 1.8rem; }
  .landing-text { font-size: 1rem; }
  .cta-btn { padding: 14px 28px; font-size: 1.2rem; width: 80%; margin: 10px auto; display: block; }
  .video-grid { grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); }
  .footer-text { font-size: 1rem; }
  .social-icon { font-size: 1.5rem; }
  .section-title { font-size: 1.8rem; }
  .section-text, .product-text, .sponsored-text { font-size: 1rem; }
  .products-grid, .sponsored-grid { grid-template-columns: 1fr; }
  .sponsored-grid { padding: 10px; }
  .sponsored-card { min-width: 200px; max-width: 200px; }
  .sponsored-title { font-size: 1.5rem; }
  .bio-container { flex-direction: column; align-items: center; }
  .section-text.accent-text { text-align: center; }
  .christopher-pic { width: 200px; height: 200px; }
  .chips-form { max-width: 100%; }
}